import React from 'react';
import styled from 'styled-components';

interface ITextCellProps {
  rowData: any;
  dataKey: string;
}

const Text = styled.p``;

// function BooleanCell({ dataKey, rowData }: ITextCellProps) {
//   const dataKeysArray = dataKey.split('.');

//   if (dataKeysArray.length === 1) {
//     return <Text>{rowData[dataKeysArray[0]] || ''}</Text>;
//   }

//   if (rowData[dataKeysArray[0]]) {
//     console.log("is it reaching here")
//     const nestedValue = dataKeysArray.reduce(
//       (acc, key) => acc[key],
//       rowData,
//     );

//     return <Text>{nestedValue ? 'Yes':'No'}</Text>;
//   }

//   return <Text>deleted user</Text>
// }

function BooleanCell({ dataKey, rowData }: ITextCellProps) {
  const dataKeysArray = dataKey.split('.');

  // Reduce the rowData using the keys in dataKeysArray. This works for both single and nested keys.
  const value = dataKeysArray.reduce((acc, key) => (acc != null ? acc[key] : undefined), rowData);

  // If the value is a boolean, display "Yes" for true and "No" for false.
  if (typeof value === 'boolean') {
    return <Text>{value ? 'Yes' : 'No'}</Text>;
  }

  // Otherwise, display the value (or an empty string if falsy).
  return <Text>{value || ''}</Text>;
}

export default BooleanCell;
