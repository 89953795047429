/* eslint-disable react/jsx-props-no-spreading */
import { COLOR_PRIMARY } from 'constants/colors';
import React from 'react';
import { Table } from 'rsuite';
import styled from 'styled-components';
import { DataTableMenuBtn } from 'types';

interface IViewContactLogCellProps {
  menuBtn?: DataTableMenuBtn;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Btn = styled.button`
  background-color: ${COLOR_PRIMARY};
  border-radius: 4px;
  p {
    margin: 0;
    padding: 2px;
    color: #fff;
    font-size: 12px;
  }
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
`;

// get Table elements
const { Cell } = Table;

function ViewContactLogCell({
  rowData,
  menuBtn,
  ...props
}: any & IViewContactLogCellProps) {
  if (menuBtn) {
    const { label, logView } = menuBtn;

    return (
      <Cell {...props}>
        <CellContainer>
          <Btn onClick={() => logView(rowData)}>
            <p>{label} </p>
          </Btn>
        </CellContainer>
      </Cell>
    );
  }

  return (
    <Cell {...props}>
      <CellContainer>
        <p>View Log </p>
      </CellContainer>
    </Cell>
  );
}

export default ViewContactLogCell;

ViewContactLogCell.defaultProps = {
  menuBtn: undefined,
};
